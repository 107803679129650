<template>

    <div class="popup active">

		<div class="notice_popup field agent">
                <div class="close">
                    <img src="/images/close.png" alt="닫기" @click="$emit('close', false)"/>
                </div>
                <div class="tit">+ 매니저 배정</div>
				<div class="field">프로젝트에 배정할 매니저 : <span>{{admMgrMberNm}}({{admMgrLoginId}})</span></div>
				<div class="field last">프로젝트명 : <span>'{{projectNm}}'</span></div>

				<div class="select-btn">
                    <div class="btn" @click="$emit('close', false)">취소</div>
                    <div class="btn" @click="save()">확인</div>
                </div>

		</div>
    </div>

</template>

<script>

export default {

	components : {		
	},

	props: ['param'],

	data() {
		return {
			projectSeq : this.param.projectSeq,			
			projectNm : this.param.projectNm,
		
			admMgrMberSeq : this.param.admMgrMberSeq,		// 선택한 매니저
			admMgrMberNm : this.param.admMgrMberNm,
			admMgrLoginId : this.param.admMgrLoginId
		};
	},
	beforeCreate() {
		// console.log('beforeCreate');
	},
	created() {
		// console.log('created');
	},
	beforeMount() {
		// console.log('beforeMount');		
	},
	mounted() {
		// console.log('mounted');
	},
	beforeUpdate() {
		// console.log('beforeUpdate');
	},
	updated() {
		// console.log('update');
	},
	beforeDestroy() {
		// console.log('beforeDestroy');
	},
	destroyed() {
		// console.log('destroyed');
	},

	methods: {

		save() {

			var input = {
				projectSeq	: this.projectSeq,
				admMgrMberSeq	: this.admMgrMberSeq
			}

			this.$.httpPost('/api/prj/adm/updateProjectAgent', input)
				.then(() => {
					this.$emit('close', true);
				})
				.catch(err => {
					// console.log('============================='); 
					// console.log(err);
					// console.log(err.response);
					// console.log('============================='); 

					alert(err.response.data.error_description);
				});	
					

		}
	},
};
</script>
